import React, { useState, useEffect } from 'react';
import './Account.scss';
import Footer from '../Footer/Footer';
import AccountInfoModal from '../AccountInfoModal/AccountInfoModal';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getDomainName from '../Utils/DomainName';

export default function Account(props) {
  const [isUpdatingInfo, setIsUpdatingInfo] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    setUserData();
  }, [])

  function triggerModal(open, type) {
    switch (type) {
      case 'password':
        toast('Change Password Success')
        setIsUpdatingPassword(open);
        break;
      case 'accountInfo':
        setIsUpdatingInfo(open);
        break;
      default:
        break;
    }
  }

  function setUserData() {
    let domainName = getDomainName(window.location.hostname);
    const url = `https://${domainName}/mobile/app/ecustomer/v1/api/fetch_user_details?customer_email=${localStorage.email || ''}`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(data.status === 0) {
        window.localStorage.clear();
        props.history.push('/login');
      }
      if(data.data) {
        setUser(data.data);
      }
    })
  }

  function updateUserData(data) {
    let domainName = getDomainName(window.location.hostname);
    const url = `https://${domainName}/mobile/app/ecustomer/v1/api/update_user_details`
    fetch(url, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
      body: JSON.stringify(data)
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      setUser(data.data);
    })
  }

  function changePasswordToast() {
    toast('Change Password Success!')
    setIsUpdatingPassword(false);
  }

  return (
    <div className="account-page-container">
      <div className="content-container">
      <div className="title">Account</div>
        <div className="user-info-content-container">
        <div className="name">{(user.first_name !== undefined ? user.first_name + ' ' + user.last_name : '')}</div>
        <div className="info-detail-1">Email: {user.email_address || ''}</div>
        <div className="info-detail">Phone: {user.mobile_phone}</div>
          <div className="update-info-button" onClick={()=>{setIsUpdatingInfo(true);}}>Update Info</div>
        </div>
        <div className="password-content-container">
          <div className="info-detail">Password: *******</div>
          <div className="change-password-button" onClick={()=>{setIsUpdatingPassword(true);}}>Change Password</div>
        </div>
        <Footer />
      </div>
      {
        isUpdatingInfo && !isUpdatingPassword && (
          <AccountInfoModal updateUserData={updateUserData} account={user} close={triggerModal} />
        )
      }
      {
        isUpdatingPassword && !isUpdatingInfo && (
          <ChangePasswordModal successToast={changePasswordToast} account={user} close={triggerModal} />
        )
      }
    </div>
  )
}