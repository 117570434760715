import React, { useState, useEffect } from 'react';
import CardInfo from '../CardInfo/CardInfo';
import Footer from '../Footer/Footer'
import './Billing.scss';
import CardModal from '../CardModal/CardModal';
import getDomainName from '../Utils/DomainName';
import { injectStripe } from 'react-stripe-elements';

function Billing(props) {
  const [isUpdatingCard, setIsUpdatingCard] = useState({});
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [cards, setCards] = useState([]);
  const [customer, setCustomer] = useState('');
  const [customerStripeId, setCustomerStripeId] = useState('');

  useEffect(() => {
    fetchUser()
  }, [])
  
  function fetchUser() {
    let domainName = getDomainName(window.location.hostname);
    const url = `https://${domainName}/mobile/app/ecustomer/v1/api/fetch_user_details?customer_email=${localStorage.email || ''}`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(data.status === 0) {
        window.localStorage.clear();
        props.history.push('/login');
      }
      if(data.data) {
        console.log('data.data', data.data);
        setCustomer(data.data);
        fetchStripeCustomer(data.data.email_address)
      }
    })
  }

  function fetchStripeCustomer(email) {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/ecommerce/api/admin_dashboard_v2/subscription/fetch_customer?customer_email=${email}`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      console.log('datdasasda', data);
      fetchCustomerPaymentCards(data.customer_stripe_id);
      setCustomerStripeId(data.customer_stripe_id);
    })
  }

  function setUpdateModal(data) {
    setIsUpdatingCard(data);
  }

  function setAddModal(data) {
    setIsAddingCard(data);
  }

  const updateCard = function(data){
    const onCardUpdate = function(resData){
      fetchCustomerPaymentCards(customerStripeId);
    }
    data.stripe_id = customerStripeId
    let domainName = getDomainName(window.location.hostname, 8000);
    const baseUrl = `https://${domainName}/ecommerce/api/stripe/`;
    const updateCardUrl = `${baseUrl}update_customer_card`;
    sendData(updateCardUrl, 'POST', data, onCardUpdate)
  }

  // Add a payment card
  const addCard = function(data){
    let createToken = props.stripe.createToken({type: "card", ...data});
    createToken.then(data=>{
      let domainName = getDomainName(window.location.hostname);
      const baseUrl = `https://${domainName}/ecommerce/api/stripe/`;
      const createCardURL = `${baseUrl}create_customer_card`;
      sendData(createCardURL, 'POST', {'card_src': data.token.id, 'stripe_id': customerStripeId}, onCardCreation);
    });
    const onCardCreation = function(data){
      fetchCustomerPaymentCards(customerStripeId);
    }
  }

  const fetchCustomerPaymentCards = function(stripe_id){
    const onFetchCustomerData = function(content){
      if(content.data){
        setDefaultPaymentSource(content.data.default_source);
        if(content.data.sources){
          buildCustomerCards(content.data.sources.data, content.data.default_source);
        }
      }
    }
    const customerStripeId = stripe_id;
    let domainName = getDomainName(window.location.hostname);
    const baseUrl = `https://${domainName}/ecommerce/api/stripe/`;
    let fetchCardURL = `${baseUrl}get_customer?stripe_id=${customerStripeId}`;
    sendData(fetchCardURL, 'GET', null, onFetchCustomerData);
  }

  // Delete payment card
  const deleteCustomerCard = function(data){
    const onDeleteCard = function(resData){
      setCards(cards.filter(({ id }) => id !== resData.data.id));
    }
    let {stripeId, cardId} = data;
    let domainName = getDomainName(window.location.hostname);
    const baseUrl = `https://${domainName}/ecommerce/api/stripe/`;
    let deleteCardUrl = `${baseUrl}delete_customer_card?stripe_id=${stripeId}&card_id=${cardId}`;
    sendData(deleteCardUrl, 'DELETE', null, onDeleteCard);
  }

  // Set default payment card
  const setDefaultPaymentSource = function(content){
    let {stripeId, cardId} = content;
    const onSetDefaultSource = function(resData){
      fetchCustomerPaymentCards(stripeId);
    }
    let data = {stripe_id: stripeId, card_id: cardId};
    let domainName = getDomainName(window.location.hostname);
    const baseUrl = `https://${domainName}/ecommerce/api/stripe/`;
    let setdefaultCardUrl = `${baseUrl}set_customer_default_source`;
    sendData(setdefaultCardUrl, 'POST', data, onSetDefaultSource);
  }


  const buildCustomerCards = function(sources, defaultSrc){
    let cards = sources.filter((source)=>{
      source.default_source = false;
      if(defaultSrc === source.id){
        source.default_source = true
      }
      if(source.object === "card"){
        return source;
      }
    });
    setCards(cards);
  }

  const sendData = function(url, method, data, callback){
    let extras = {
        headers: new Headers({'Content-Type': 'application/json'}),
        method: method
    };
    if(method === 'POST'){
        extras.body = JSON.stringify(data);
    }
    
    fetch(url, extras)
    .then(response=>{
        return response.json();
    })
    .then(data=>{
        callback(data)
    })
    .catch(err=>{
        console.log("Send data>>>>>>"+ err.message);
    })
}

  return (

    <div className="billing-container">
      <div className="content-container">
        <div className="billing-title">Billing</div>
        <div className="billing-details-container">
        <div className="title">{cards && cards.length > 1 ? 'Credit Cards' : 'Credit Card'}</div>
          <div className="cards-container">
            {
              cards.map((card, key)=>{
                return <CardInfo key={key} card={card} editCard={()=>{setIsUpdatingCard({card: card, key: key})}}/>
              })
            }
          </div>
          <div className="update-billing-button-container">
            <div className="update-billing-button" onClick={()=>{setAddModal(true);}}>Add Card</div>
          </div>
        </div>
      <Footer />
      </div>
        {isAddingCard && <CardModal stripeId={customerStripeId} card={{card:{}}} close={setAddModal} action='add' title={'Add Card'} addCard={addCard} setAsDefault={setDefaultPaymentSource}/>}
        {isUpdatingCard.card && !isAddingCard && <CardModal stripeId={customerStripeId} card={isUpdatingCard.card} close={setUpdateModal} deleteCard={deleteCustomerCard} action='update' title={'Update Card'} setAsDefault={setDefaultPaymentSource} updateCard={updateCard}/>}
    </div>

  )
}

export default injectStripe(Billing)