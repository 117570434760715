import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import Footer from '../Footer/Footer';
import './HomeAndDevices.scss';
import UpgradeSubscriptionModal from '../UpgradeSubscriptionModal/UpgradeSubscriptionModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getDomainName from '../Utils/DomainName';

export default function HomeAndDevices(props){
  const [houses, setHouses] = useState([]);
  const [house, setHouse] = useState(false);
  const [customer, setCustomer] = useState({})
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [validSubscription, setValidSubscription] = useState(false);
  useEffect(()=>{
    fetchUser();
  },[])
  
  function fetchUser() {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/mobile/app/ecustomer/v1/api/fetch_user_details?customer_email=${localStorage.email || ''}`;
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      if(data.status === 0) {
        window.localStorage.clear();
        props.history.push('/login');
      }
      if(data.data) {
        setCustomer(data.data);
        getHubAndHouse(data.data);
      }
    })
  }

  function getHubAndHouse(customerData, index) {
    if(!customerData.ds_customer_id) {
      return 'no customer id'
    }
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/mobile/app/ecustomer/v1/api/fetch_house_hubs?ds_customer_id=${customerData.ds_customer_id}`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response=>{
      return response.json();
    })
    .then(content=>{
      if(content.data){
        content.data[0].status = 'active'
        setHouses(content.data);
        setHouse(content.data[0]);
        fetchSubscription(content.data[0].house.uuid.replace(/-/g, ''))
      } 
    })
  }
  
  function fetchSubscription(houseId) {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/ecommerce/api/mobile/subscription/get?house_id=${houseId}`
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
    })
    .then(response => {
      return response.json()
    })
    .then(data=>{
      setValidSubscription(data);
    })
  }

  function addSubscription(data) {
    let domainName = getDomainName(window.location.hostname);
    let url = `https://${domainName}/api/admin_dashboard_v2/subscription/add_subscription`;
    fetch(url, {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': window.localStorage.session_key
      }),
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(response=>{
      return response.json();
    })
    .then(data=>{
      console.log('data', data);
      toast('Successfully added subscription')
      setIsUpgrading(false);
    })
  }

  function setHouseColor(key) {
    let newHouses = [...houses];
    newHouses.map((house, index)=>{
      house.status = ''
    });
    if(newHouses.length> 0) {
      newHouses[key].status = 'active';
      setHouses(newHouses); 
    }
  }

  function renderHouseCircle(data, key) {
    let subscriptionType = ' Business';
    let isBusiness = (subscriptionType === ' Business');
    return (
      <div key={key} className={`${houses[key].status} ` + "house-choice-container"} onClick={()=>{
          fetchSubscription(data.house.uuid.replace(/-/g, ''));
          setHouse(data);
          setHouseColor(key);
        }}>
        <div className="house-choice" style={{ fontSize: (isBusiness ? "26px" : "29px") }}>
          <ReactSVG
            beforeInjection={svg => {
              svg.classList.add('house-svg')
            }}
            src={require("../assets/home.svg")}
          />
        </div>
        <div className="house-name">{data.house.name && data.house.name.replace(' Home', '') || 'no name'}</div>
      </div>
    )
  }
  function renderHub(hub, key){
    return (
      <div className={"hub-container" + (key === 0 ? key : '')} key={key}>
          <ReactSVG  beforeInjection={svg => {
              svg.classList.add('hub-svg')
              svg.setAttribute('style', 'width: 55px')
            }}
            src={require("../assets/hub_line.svg")}
          />
          <div className="hub-info-container">
            <div className="hub-name">{hub.name.replace(' Home', '')}</div>
            <div className="hub-info-detail">Series No.: {hub.agent_version || '123'}</div>
            <div className="hub-info-detail">Cameras: {hub.camera_qty}</div>
          </div>
      </div>
    )
  }

  function renderHubAndHouse() {
    console.log('validSubscription', validSubscription);
    console.log('house', house);
    if(house) {
      return (
        <div className="house-and-hub-info-container">
          <div className="plan-name">{validSubscription.monitoring === '24' ? '24 Hour LiveSentinel Plan' : 'Nighttime Sentinel Plan'}</div>
          <div className="info-detail">{house.house.address_1} {house.house.address_2}</div>
          <div className="info-detail">Subscription: $63{'isAnnual' || '' ? 'x12 mo,' : ''} {'' || ''}</div>
          <div className="info-detail">Next Bill: {validSubscription.next_charge_date || ''}</div>
          <div className="action-button-container">
            <div className="action-button-container-content">
              <div className="upgrade-button" style={{ marginLeft: '0px'}} onClick={()=>{setIsUpgrading(true)}}>Add Plan</div>
            </div>
          </div>
          <div className="hubs-container">
            {house.hubs.map((hub, key)=> {
              return (
                renderHub(hub, key)
                )
              })}
          </div>
        </div>
      )
    }
  }
  return (
    <div className="hub-and-house-container">
        {
          houses.length > 0 && (
          <div className="content">
                <div className="house-choices">
              {houses.map((house, key)=>{
                return (
                  renderHouseCircle(house, key)
                  )
                })}
            </div>
            {renderHubAndHouse()}
            <Footer hidden={true}/>
          </div>
        )
      }
      {isUpgrading && <UpgradeSubscriptionModal hubs={house.hubs} house={house.house} addSubscription={addSubscription} close={()=>{setIsUpgrading(false)}} user={customer}/>}
      <ToastContainer style={{color: 'red !important'}} hideProgressBar={true} position={toast.POSITION.BOTTOM_CENTER} />
    </div>
  )
}